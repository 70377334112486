<template>
  <body-with-header title="کارفرمایان / زمین ها" class="main-box">
    <CRow class="w-100">
      <CCol md="3">
        <employer @getFarms="getFarms"/>
      </CCol>
      <CCol md="9">
        <farms :userId="userId" :userType="userType" :userName="userName" />
      </CCol>
    </CRow>
  </body-with-header>
</template>

<script>
import employer from "./subForm/employer.vue";
import farms from "./subForm/farms.vue";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
export default {
  name: "advicerFarms",
  components: {
    BodyWithHeader,
    employer,
    farms,
  },
  data() {
    return {
      userId: 0,
      userType: null,
      userName : "لیست تمام زمین های کارفرمایان",
    };
  },
  computed: {},
  methods: {
    getFarms(userId , userType , userName){
      this.userId = userId;
      this.userType = userType;
      this.userName = `لیست تمام زمین های ${userName}`;
    }
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.main-box {
  float: right;
  width: 100%;
}
</style>
