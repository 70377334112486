<template>
  <div class="farms-box">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست زمین ها ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <no-data
      message="هیچ زمینی به شما اختصاص داده نشده"
      show
      v-if="farmsList.length == 0"
    >
      <i class="fa fa-frown-o" aria-hidden="true"></i>
    </no-data>
    <div v-else class="farm-list">
      <h5>{{ userName }}</h5>
      <div style="width: 100%; height: 83vh !important; overflow-y: auto">
        <div class="farm-item" v-for="(item, index) in farmsList" :key="index">
          <img
            @error="
              () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
            "
            :src="item.image"
            alt=""
          />
          <div class="farm-info-item">
            <div class="info-item-box" style="width: 40%">
              <h4>مالک (زمین)</h4>
              <h6>{{ item.farmer }} ({{ item.title }})</h6>
              <h4>هکتار</h4>
              <h6>{{ item.farmArea }}</h6>
              <h4>بخش (روستا)</h4>
              <h6>{{ item.district }} ({{ item.village }})</h6>
            </div>
            <div class="info-item-box" style="width: 60%">
              <h4>محصول (نوع محصول)</h4>
              <h6>{{ item.product }} ({{ item.productType }})</h6>
              <h4>استان (شهرستان)</h4>
              <h6>{{ item.state }} ({{ item.city }})</h6>
              <h4>نوع کشت (تاریخ کشت)</h4>
              <h6>{{ item.cultivationsType }} ({{ item.cultivationsDate }})</h6>
            </div>
          </div>
          <div class="farm-info-btn">
            <button
              v-c-tooltip="{
                content: 'برنامه غذایی',
                placement: 'right',
              }"
              @click="loadSubForm(item.farmId, 'DietPlan', item.title)"
            >
              <i class="fa fa-cutlery" aria-hidden="true"></i>
            </button>
            <button
              v-c-tooltip="{
                content: 'توصیه ها',
                placement: 'right',
              }"
              @click="loadSubForm(item.farmId, 'Advice', item.title)"
            >
              <i class="fa fa-graduation-cap" aria-hidden="true"></i>
            </button>
            <button
              v-c-tooltip="{
                content: 'کرمندان مزرعه',
                placement: 'right',
              }"
            >
              <i class="fa fa-user" aria-hidden="true"></i>
            </button>
            <button v-c-tooltip="{ content: 'توصیه ها', placement: 'right' }">
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
            </button>
            <button
              v-c-tooltip="{
                content: 'تاریخ ها',
                placement: 'right',
              }"
            >
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      modal-class="drawer"
      in-class="animate__fadeInRight"
      out-class="animate__fadeOutRight"
    >
      <component v-if="subForm != null" v-bind:is="subForm" :farmId="farmId" />
    </VueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import polyGonStructure from "../../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import { apiUrlRoot } from "../../../constants/config";
import DietPlan from "../../../containers/DietPlan";
import Advice from "../../../containers/Advice";
import NoData from "../../../components/no-data.vue";
export default {
  name: "userPartnerFarms",
  data() {
    return {
      loading: false,
      farmsList: [],
      farmId: null,
      modelShowState: false,
      modalTitle: "",
      subForm: null,
    };
  },
  components: {
    NoData,
    Advice,
    DietPlan,
  },
  props: ["userId", "userType", "userName"],
  watch: {
    userId: function () {
      this.farmsList = [];
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("connectionAdvicer", ["GetAllPartnerFarms"]),
    async fetchData() {
      this.loading = true;
      let resultAdvicer = await this.GetAllPartnerFarms({
        userId: this.userId,
        userType: this.userType,
      });
      this.loading = false;
      if (resultAdvicer.length > 0) {
        this.farmsList = resultAdvicer.map((item) => {
          let polygon = item.area.substring(
            item.area.indexOf("("),
            item.area.indexOf(")")
          );
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          const newArray = [polygon];
          polyGonStructure.features[0].geometry.coordinates = newArray;
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          item.farmArea = Intl.NumberFormat("fa").format(
            (farmArea / 10000).toFixed(2)
          );
          item.image = `${apiUrlRoot}Farm/${item.image}`;
          return { ...item };
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا در دریافت اطلاعات <br /><br />`,
          type: "error",
        });
      }
    },
    loadSubForm(id, subForm, farmName) {
      switch (subForm) {
        case "Advice":
          this.modalTitle = `توصیه های زمین ${farmName}`;
          break;
        case "DietPlan":
          this.modalTitle = `برنامه های غذایی زمین ${farmName}`;
          break;
        default:
          break;
      }
      this.farmId = id;
      this.subForm = subForm;
      this.modelShowState = true;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.farms-box {
  height: 85vh !important;
}
.farm-list {
  height: 85vh !important;
}
.farm-list > h5 {
  width: 99%;
  text-align: center;
  margin-top: 1em;
  color: darkgreen;
  border: 1px solid;
  padding: 0.2em 0;
  border-radius: 10px;
  background-color: #fff;
}
.farm-item {
  float: right;
  margin: 1em;
  background-color: #fff;
  border-radius: 10px 0px 0px 10px;
}
.farm-info-item {
  float: right;
  width: 81%;
  margin-top: 0.5em;
}
.farm-info-btn {
  float: right;
  width: 4%;
  margin-top: 0.5em;
}
.farm-info-btn button {
  float: left;
  border: none;
  width: 1.6vw;
  margin: 0.1em 0%;
  height: 2.8vh;
  border-radius: 100px;
  font-size: 0.8em;
  clear: both;
  background-color: darkgreen;
  color: #fff;
  padding: 0.6em;
}
.farm-item img {
  float: right;
  width: 8vw;
  margin: 0.5em;
  height: 15vh;
}
.info-item-box {
  float: right;
  height: 15vh;
}
.info-item-box h4 {
  float: right;
  font-size: 1.2em;
  color: #959595;
  margin: 1em 0em 0px 0px;
  width: 40%;
  text-align: right;
  font-weight: bold;
}
.info-item-box h6 {
  float: right;
  font-size: 1.2em;
  color: darkgreen;
  margin: 1em 0px 0px 0em;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
