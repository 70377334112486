<template>
  <div class="employer-box">
    <div class="employer-list">
      <vue-element-loading
        :active="loading"
        text="دریافت لیست کارفرمایان ... "
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <div v-if="partnerList.length > 0">
        <div
          class="main-card cursor-pointer d-flex justify-content-between"
          v-for="(item, index) of partnerList"
          :key="index"
          @click="getFarmsList(item.id, item.userType, item.fullName)"
        >
          <div>
            <h6 class="mb-2">{{ item.typeName }}</h6>
            <ul>
              <li>
                <h6>
                  {{ item.fullName }} ({{ item.farmCount }})
                </h6>
              </li>
              <li>
                <h6>{{ item.phone }}</h6>
              </li>
            </ul>
          </div>
          <div class="flex-center">
            <CButton  :color="selectedEmployer == item.id ? 'secondary' : 'primary'" class="y-center-g-10" size="sm" :variant="selectedEmployer == item.id ? 'filled' : 'outline'">
              <font-awesome-icon icon="check" v-if="selectedEmployer == item.id"/>
              <span>نمایش</span>
            </CButton>
          </div>
        </div>
      </div>
      <no-data
        v-else
        :show="!loading"
        message="با هیچ کارفرمایی همکاری نمی کنید"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NoData from "../../../components/no-data.vue";
export default {
  name: "userPartnerEmployer",
  components: {NoData},
  data() {
    return {
      loading: false,
      partnerList: [],
      selectedEmployer: 0,
    };
  },
  methods: {
    ...mapActions("connectionAdvicer", ["GetAllPartner"]),
    async fetchData() {
      this.loading = true;
      let resultAdvicer = await this.GetAllPartner({});
      this.loading = false;
      if (resultAdvicer.succeeded) {
        this.partnerList = resultAdvicer.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${resultAdvicer.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    getFarmsList(id, type, userName) {
      this.selectedEmployer = id;
      this.$emit("getFarms", id, type, userName);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
ul{
  margin: 0;
}
.employer-box {
  height: 85vh !important;
}
.employer-list {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
